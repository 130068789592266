import { Box, Button, colors, Container, Divider, Modal, Slider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import axiosInstance from "../../axiosInstance";
import axios from "axios";

interface FeedbackModalProps {
    roomId: number;
    open: boolean;
    onClose: () => void;
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: colors.grey[300],
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '25px'
};

const commonSliderStyle = {
    ".MuiSlider-mark": {
        visibility: "hidden"
    },
    ".MuiSlider-track": {
        visibility: "hidden"
    },
    ".MuiSlider-rail": {
        opacity: 1,
        outline: `10px solid ${colors.grey[400]}`,
        backgroundColor: colors.grey[400],
    },
    ".MuiSlider-markLabel": {
        color: colors.grey[900]
    },
    width: "200px",
};

function valuetext(value: number) {
    return `${value}°C`;
}

export default function FeedbackModal(props: FeedbackModalProps) {
    const [air, setAir] = useState<number>(0);
    const [temperature, setTemperature] = useState<number>(0);
    const [humidity, setHumidity] = useState<number>(0);
    const [noise, setNoise] = useState<number>(0);
    const [light, setLight] = useState<number>(0);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const handleSubmit = async () => {
        const body = {
            air_quality: air,
            temperature,
            humidity,
            acoustic_comfort: noise,
            visual_comfort: light,
        };
        setSubmitting(true);

        try {
            const response = await axiosInstance.post(`/api/rooms/${props.roomId}/liveview/feedback`, body);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Error submitting feedback:', error.response?.data || error.message);
            } else {
                console.error('Unexpected error:', error);
            }
        }
        setSubmitting(false);
        props.onClose();
    };

    return (<Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={modalStyle}>
            <Typography align="center">User feedback</Typography>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[800],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Air Quality</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too dusty',
                        }]}
                    min={0}
                    max={1}
                    value={air}
                    onChange={(_, value) => setAir(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (air == 0 ? "green" : "red"),
                        },
                        width: "100px"
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Temperature</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: -1,
                            label: 'too cold',
                        },
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too hot',
                        }]}
                    min={-1}
                    max={1}
                    value={temperature}
                    onChange={(_, value) => setTemperature(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (temperature == 0 ? "green" : "red")
                        }
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Humidity</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: -1,
                            label: 'too dry',
                        },
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too humid',
                        }]}
                    min={-1}
                    max={1}
                    value={humidity}
                    onChange={(_, value) => setHumidity(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (humidity == 0 ? "green" : "red")
                        }
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Acoustic Comfort</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too noisy',
                        }]}
                    min={0}
                    max={1}
                    value={noise}
                    onChange={(_, value) => setNoise(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (noise == 0 ? "green" : "red"),
                        },
                        width: "100px"
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Visual Comfort</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: -1,
                            label: 'too dark',
                        },
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too light',
                        }]}
                    min={-1}
                    max={1}
                    value={light}
                    onChange={(_, value) => setLight(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (light == 0 ? "green" : "red")
                        }
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />

            <Button onClick={handleSubmit}
                sx={{
                    display: "block",
                    margin: "auto",
                    borderRadius: "20px",
                    textTransform: "capitalize"
                }}
                variant="contained"
                disabled={submitting}
            >
                Submit
            </Button>

        </Box>
    </Modal>)
}