import { Units, UnitToPretty } from "../../Common/Enums";
import { ValueWithBounds } from "../StatGauges";
import ScaleLine, { TickMarkDescriptor } from "./ScaleLine";

export default function TemperatureScaleLine({ valueWithBounds }: { valueWithBounds: ValueWithBounds }) {

  let startValue = 10;
  let endValue = 40;
  let tickMarkers: TickMarkDescriptor[] = [{
    colorTillNextTickMark: "red",
    tickValue: 15,
  }, {
    colorTillNextTickMark: "red",
    tickValue: 35,
  }];

  if (valueWithBounds && valueWithBounds.hasBounds()) {
    startValue = valueWithBounds.optimal_bounds![0] - 5;
    endValue = valueWithBounds.optimal_bounds![1] + 5;
    tickMarkers = [{
      colorTillNextTickMark: "green",
      tickValue: valueWithBounds.optimal_bounds![0],
    },
    {
      colorTillNextTickMark: "red",
      tickValue: valueWithBounds.optimal_bounds![1],
    }];
  }

  return (
    <ScaleLine
      startValue={startValue}
      endValue={endValue}
      colorTillFirstTickMark="red"
      currentValue={valueWithBounds.value}
      totalWidth={150}
      hideEndTickMark={true}
      hideStartTickMark
      hideStartTickLabel
      hideEndTickLabel
      tickMarkers={tickMarkers}
      units={UnitToPretty(Units.DegreesCelsius)}
    ></ScaleLine >
  );
}
