
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { colors, Divider, Card, CardContent } from "@mui/material";
import TemperatureScaleLine from "./ScaleLines/TemperatureScaleLine";
import AirQualityScaleLine from "./ScaleLines/AirQualityScaleLine";
import HumidityScaleLine from "./ScaleLines/HumidityScaleLine";
import AcousticComfortScaleLine from "./ScaleLines/AcousticComfortScaleLine";
import VisualComfortScaleLine from "./ScaleLines/VisualComfortScaleLine";
import { valueOrDefaultString } from "../Common/Helpers";
import { Units, UnitToPretty } from "../Common/Enums";

export interface CurrentStats {
    air_quality: ValueWithBounds,
    temperature: ValueWithBounds,
    humidity: ValueWithBounds,
    acoustic_comfort: ValueWithBounds,
    visual_comfort: ValueWithBounds,
}

export function GetDefaultCurrentStatsValues(): CurrentStats {
    return {
        air_quality: new ValueWithBounds(),
        temperature: new ValueWithBounds(),
        humidity: new ValueWithBounds(),
        acoustic_comfort: new ValueWithBounds(),
        visual_comfort: new ValueWithBounds(),
    }
};

export class ValueWithBounds {
    value?: number;
    optimal_bounds?: number[] = []

    constructor(value?: number, optimal_bounds?: number[]) {
        this.value = value;
        this.optimal_bounds = optimal_bounds;
    }

    hasBounds(): boolean { return !!this.optimal_bounds && this.optimal_bounds.length === 2; }

    inBounds(): boolean { return (this.value != null && this.hasBounds() && this.optimal_bounds![0] <= this.value && this.value <= this.optimal_bounds![1]); }
}

export default function LiveViewGauges({ stats, isRoomLiveViewPresentation }: { stats: CurrentStats, isRoomLiveViewPresentation: boolean }) {
    return (
        <div className="grid grid-cols-5 grid-rows-4 justify-items-center items-center" style={{ gridTemplateRows: "repeat(4, auto)" }}>
            <div className="col-start-1 col-end-2 row-start-1 row-end-2">
                <Gauge
                    width={150}
                    height={150}
                    value={stats.air_quality.value != null && stats.air_quality.hasBounds() ? 1 : 0}
                    valueMax={1}
                    text={({ value }) => `${valueOrDefaultString(stats.air_quality.value, '-')}\n${UnitToPretty(Units.PPM)}`}
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                            fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: stats.air_quality.inBounds() ? colors.green[300] : colors.red[200],
                        },
                    })}
                />
            </div>

            <div className="text-sm col-start-1 col-end-2 row-start-2 row-end-3">Air Quality</div>
            {isRoomLiveViewPresentation &&
                <div className="text-xl font-bold col-start-1 col-end-2 row-start-3 row-end-4">{ValueWithBoundsToEvaluationString(stats.air_quality)}</div>}
            {isRoomLiveViewPresentation &&
                <div className="col-start-1 col-end-2 row-start-4 row-end-5 pb-4">
                    <AirQualityScaleLine valueWithBounds={stats.air_quality} />
                </div>
            }
            <div className="grid grid-cols-subgrid grid-rows-subgrid bg-yellow-100 col-start-2 col-end-4 row-start-1 row-end-5 justify-items-center items-center">

                <div className="grid grid-cols-subgrid grid-rows-subgrid col-start-1 col-end-3 row-start-2 row-end-4 items-center">
                    {isRoomLiveViewPresentation && <div className="text-sm w-full col-start-1 col-end-3 row-start-1 row-end-2">Thermal <br /> Comfort</div>}

                    {isRoomLiveViewPresentation && <div className="text-xl font-bold col-start-1 col-end-3 row-start-2 row-end-3">{ValueWithBoundsToEvaluationString(stats.temperature)}</div>}

                    {!isRoomLiveViewPresentation &&
                        <div className="text-sm w-full col-start-1 col-end-3 row-start-1 row-end-2"><Divider
                            variant="middle"
                            style={{
                                paddingTop: 30,
                                borderBottomWidth: 1,
                                borderBottomColor: colors.grey[500],
                            }}
                        /></div>}

                    {!isRoomLiveViewPresentation && <div className="text-xl col-start-1 col-end-3 row-start-2 row-end-3">Thermal Comfort</div>}
                </div>

                <div className="col-start-1 col-end-2 row-start-1 row-end-2">
                    <div style={{ margin: "0 auto" }}>
                        <Gauge
                            width={150}
                            height={150}
                            value={stats.temperature.value != null && stats.temperature.hasBounds() ? 1 : 0}
                            valueMax={1}
                            text={({ value }) => `${valueOrDefaultString(stats.temperature.value, '-')}\n${UnitToPretty(Units.DegreesCelsius)}`}
                            sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                    fontWeight: "bold",
                                },
                                [`& .${gaugeClasses.valueArc}`]: {
                                    fill: stats.temperature.inBounds() ? colors.green[300] : colors.red[200],
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="text-sm col-start-1 col-end-2 row-start-2 row-end-3">Temperature</div>
                {isRoomLiveViewPresentation && <div className="text-xl font-bold col-start-1 col-end-2 row-start-3 row-end-4">{ValueWithBoundsToEvaluationString(stats.temperature)}</div>}
                {isRoomLiveViewPresentation && <div className="col-start-1 col-end-2 row-start-4 row-end-5 pb-4">
                    <TemperatureScaleLine valueWithBounds={stats.temperature} />
                </div>}

                <div className="col-start-2 col-end-3 row-start-1 row-end-2">
                    <Gauge
                        width={150}
                        height={150}
                        value={stats.humidity.value != null && stats.humidity.hasBounds() ? 1 : 0}
                        valueMax={1}
                        text={({ value }) => `${valueOrDefaultString(stats.humidity.value, '-')}\n${UnitToPretty(Units.Percent)}`}
                        sx={{
                            [`& .${gaugeClasses.valueText}`]: {
                                fontWeight: "bold",
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                                fill: stats.humidity.inBounds() ? colors.green[300] : colors.red[200],
                            },
                        }}
                    />


                </div>


                <div className="text-sm col-start-2 col-end-3 row-start-2 row-end-3">Humidity</div>
                {isRoomLiveViewPresentation && <div className="text-xl font-bold col-start-2 col-end-3 row-start-3 row-end-4">{ValueWithBoundsToEvaluationString(stats.humidity)}</div>}
                {isRoomLiveViewPresentation && <div className="col-start-2 col-end-3 row-start-4 row-end-5 pb-4">
                    <HumidityScaleLine valueWithBounds={stats.humidity} />
                </div>}
            </div>
            <div className="col-start-4 col-end-5 row-start-1 row-end-2">
                <Gauge
                    width={150}
                    height={150}
                    value={stats.acoustic_comfort.value != null && stats.acoustic_comfort.hasBounds() ? 1 : 0}
                    valueMax={1}
                    text={({ value }) => `${valueOrDefaultString(stats.acoustic_comfort.value, '-')}\n${UnitToPretty(Units.Decibel)}`}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: stats.acoustic_comfort.inBounds() ? colors.green[300] : colors.red[200],
                        },
                    }}
                />
            </div>
            <div className="text-sm col-start-4 col-end-5 row-start-2 row-end-3">Acoustic Comfort</div>
            {isRoomLiveViewPresentation && <div className="text-xl font-bold col-start-4 col-end-5 row-start-3 row-end-4">{ValueWithBoundsToEvaluationString(stats.acoustic_comfort)}</div>}
            {isRoomLiveViewPresentation && <div className="col-start-4 col-end-5 row-start-4 row-end-5 pb-4">
                <AcousticComfortScaleLine valueWithBounds={stats.acoustic_comfort} />
            </div>}
            <div className="col-start-5 col-end-6 row-start-1 row-end-2">
                <Gauge
                    width={150}
                    height={150}
                    value={stats.visual_comfort.value != null && stats.visual_comfort.hasBounds() ? 1 : 0}
                    valueMax={1}
                    text={({ value }) => `${valueOrDefaultString(stats.visual_comfort.value, '-')}\n${UnitToPretty(Units.Lux)}`}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: stats.visual_comfort.inBounds() ? colors.green[300] : colors.red[200],
                        },
                    }}
                />
            </div>
            <div className="text-sm col-start-5 col-end-6 row-start-2 row-end-3">Visual Comfort</div>
            {isRoomLiveViewPresentation && <div className="text-xl font-bold col-start-5 col-end-6 row-start-3 row-end-4">{ValueWithBoundsToEvaluationString(stats.visual_comfort)}</div>}
            {isRoomLiveViewPresentation && <div className="col-start-5 col-end-6 row-start-4 row-end-5 pb-4">
                <VisualComfortScaleLine valueWithBounds={stats.visual_comfort} />
            </div>}
        </div >
    );
}

function ValueWithBoundsToEvaluationString(valueWithBounds: ValueWithBounds): string {
    return valueWithBounds.value != null && valueWithBounds.hasBounds() ? valueWithBounds.inBounds() ? "Good" : "Poor" : "Unknown";
}