import { useEffect } from 'react';
import { usePilotContext } from '../pilotContext';
import keycloak from '../Keycloak'

export const useKeycloakAuthentication = () => {
    const { setPilots } = usePilotContext();

    useEffect(() => {
        keycloak.onAuthSuccess = () => {
            const userPilots = keycloak.tokenParsed && keycloak.tokenParsed['user_groups'] && keycloak.tokenParsed['user_groups'].map((strGroup: string) => Number(strGroup.split("-")[1])) || [];
            console.log("userPilots " + userPilots)
            setPilots(userPilots);
        }
    }, []);
};

