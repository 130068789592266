import InsightsGauges from "../../components/InsightsGauges";
import {
    colors,
    Divider,
    Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Metric, Units } from "../../Common/Enums";
import axios from "axios";
import axiosInstance from "../../axiosInstance";
import InfoOutlinedIcon from '@mui/icons-material/Info';

interface InsightsTabProps {
    roomId: number;
    dateFrom: Date;
    dateTo: Date;
}



export default function LiveViewTab(props: InsightsTabProps) {
    const [dateFrom, setDateFrom] = useState<Date>(props.dateFrom);
    const [dateTo, setDateTo] = useState<Date>(props.dateTo);
    const [identifiedIssues, setIdentifiedIssues] = useState<{ [key: number]: { hours: string[], days: string[], months: string[] } }>({});
    const [statistics, setStatistics] = useState<{ [key: number]: { unit: Units, mean_value: number, prc_in_bounds: number, label: string } }>({});
    const [proposals, setProposals] = useState<{ [key: number]: { Proposal: string, Explanation: string } }>({});


    useEffect(() => {
        const source = axios.CancelToken.source(); // Create a cancel token for axios

        if (!!props.roomId) {
            axiosInstance.get(
                `/api/rooms/${props.roomId}/issues_detection/first_tab_statistics`,
                {
                    params: {
                        date_from: dateFrom.toLocaleDateString("sv"),
                        date_to: dateTo.toLocaleDateString("sv")
                    },
                    cancelToken: source.token  // Use the cancel token in axios
                }
            )
                .then((response) => {
                    const data = response.data;
                    setStatistics(data.metrics);  // Handle the response data
                })
                .catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        console.log(err.message);
                    }
                });
        }

        // Cleanup to cancel the request when the component is unmounted or dependencies change
        return () => {
            source.cancel("Operation canceled by the user.");
        };
    }, [props.roomId, dateTo, dateFrom]);

    useEffect(() => {
        const source = axios.CancelToken.source(); // Create a cancel token for axios

        if (!!props.roomId) {
            axiosInstance.get(
                `/api/rooms/${props.roomId}/issues_detection/second_tab_issues_detection`,
                {
                    params: {
                        date_from: dateFrom.toLocaleDateString("sv"),
                        date_to: dateTo.toLocaleDateString("sv")
                    },
                    cancelToken: source.token  // Use the cancel token in axios
                }
            )
                .then((response) => {
                    const data = response.data;
                    setIdentifiedIssues(data.metrics);  // Handle the response data
                })
                .catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        console.log(err.message);
                    }
                });
        }

        // Cleanup to cancel the request when the component is unmounted or dependencies change
        return () => {
            source.cancel("Operation canceled by the user.");
        };
    }, [props.roomId, dateTo, dateFrom]);

    useEffect(() => {
        const source = axios.CancelToken.source(); // Create a cancel token for axios

        if (!!props.roomId) {
            axiosInstance.get(
                `/api/rooms/${props.roomId}/issues_detection/third_tab_proposals`,
                {
                    params: {
                        date_from: dateFrom.toLocaleDateString("sv"),
                        date_to: dateTo.toLocaleDateString("sv")
                    },
                    cancelToken: source.token  // Use the cancel token in axios
                }
            )
                .then((response) => {
                    const data = response.data;
                    setProposals(data.metrics);  // Handle the response data
                })
                .catch((err) => {
                    if (axios.isCancel(err)) {
                        console.log('Request canceled', err.message);
                    } else {
                        console.log(err.message);
                    }
                });
        }

        // Cleanup to cancel the request when the component is unmounted or dependencies change
        return () => {
            source.cancel("Operation canceled by the user.");
        };
    }, [props.roomId, dateTo, dateFrom]);


    const getMetricData = (metric: Metric) => {
        return statistics[metric]
            ? {
                mean: statistics[metric].mean_value,
                percentageInBounds: statistics[metric].prc_in_bounds,
                label: statistics[metric].label,
                units: statistics[metric].unit,
            }
            : {};
    };

    const getMetricProposal = (metric: Metric) => {
        return proposals[metric] && proposals[metric].Proposal &&
            <p>{proposals[metric].Proposal} {proposals[metric].Explanation ? <Tooltip title={proposals[Metric.AirQuality]?.Explanation}><InfoOutlinedIcon fontSize="small" /></Tooltip> : <></>}</p>

    };

    return (<>
        <div className="flex flex-col h-full">
            <div className="flex-initial">
                <div className="flex justify-start gap-8 my-3 mx-1 justify-between">
                    <div className="text-xs text-left">Mean value and percentage of times within bounds</div>
                    <div>
                        <span className="mr-3">Date from:</span>
                        <span className="inline-block border-solid border-2 border-gray-500 mr-8">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                selected={dateFrom}
                                onChange={(date) => setDateFrom(date ?? new Date())}
                                dateFormat="yyyy-MM-dd"
                            />
                        </span>
                        <span className="mr-3">Date to:</span>
                        <span className="inline-block border-solid border-2 border-gray-500">
                            <DatePicker
                                showMonthDropdown
                                showYearDropdown
                                selected={dateTo}
                                onChange={(date) => setDateTo(date ?? new Date())}
                                dateFormat="yyyy-MM-dd"
                            />
                        </span>
                    </div>
                </div>
                <InsightsGauges
                    props={{
                        air_quality: getMetricData(Metric.AirQuality),
                        temperature: getMetricData(Metric.Temperature),
                        humidity: getMetricData(Metric.Humidity),
                        acoustic_comfort: getMetricData(Metric.AcousticComfort),
                        visual_comfort: getMetricData(Metric.VisualComfort),
                    }}
                />
            </div>

            <div className="grid grid-cols-5 grid-rows-4 justify-items-center items-center border-solid border-2 border-gray-400 mt-4" style={{ gridTemplateRows: "repeat(4, auto)" }}>
                <div className="col-start-1 col-end-6 row-start-1 row-end-2 justify-start w-full mb-4">
                    <p className="text-left mx-2 mt-3 mb-1 font-bold">Identified issues:</p>
                    <Divider
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: colors.grey[700],
                            marginLeft: 5,
                            marginRight: 5
                        }}
                    />
                </div>
                <div className="col-start-1 col-end-2 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.AirQuality] && identifiedIssues[Metric.AirQuality].hours ? identifiedIssues[Metric.AirQuality].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AirQuality] && identifiedIssues[Metric.AirQuality].days ? identifiedIssues[Metric.AirQuality].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AirQuality] && identifiedIssues[Metric.AirQuality].months ? identifiedIssues[Metric.AirQuality].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-1 col-end-6 row-start-3 row-end-4 justify-start w-full mb-4">
                    <p className="text-left mx-2 mt-3 font-bold">Proposed actions:</p>
                    <Divider
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: colors.grey[700],
                            marginLeft: 5,
                            marginRight: 5
                        }}
                    />
                </div>

                <div className="col-start-1 col-end-2 row-start-4 row-end-5 pb-4">
                    {getMetricProposal(Metric.AirQuality)}
                </div>

                <div className="col-start-2 col-end-3 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.Temperature] && identifiedIssues[Metric.Temperature].hours ? identifiedIssues[Metric.Temperature].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Temperature] && identifiedIssues[Metric.Temperature].days ? identifiedIssues[Metric.Temperature].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Temperature] && identifiedIssues[Metric.Temperature].months ? identifiedIssues[Metric.Temperature].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-2 col-end-3 row-start-4 row-end-5 pb-4">
                    {getMetricProposal(Metric.Temperature)}
                </div>


                <div className="col-start-3 col-end-4 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.Humidity] && identifiedIssues[Metric.Humidity].hours ? identifiedIssues[Metric.Humidity].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Humidity] && identifiedIssues[Metric.Humidity].days ? identifiedIssues[Metric.Humidity].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.Humidity] && identifiedIssues[Metric.Humidity].months ? identifiedIssues[Metric.Humidity].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-3 col-end-4 row-start-4 row-end-5 pb-4">
                    {getMetricProposal(Metric.Humidity)}
                </div>

                <div className="col-start-4 col-end-5 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.AcousticComfort] && identifiedIssues[Metric.AcousticComfort].hours ? identifiedIssues[Metric.AcousticComfort].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AcousticComfort] && identifiedIssues[Metric.AcousticComfort].days ? identifiedIssues[Metric.AcousticComfort].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.AcousticComfort] && identifiedIssues[Metric.AcousticComfort].months ? identifiedIssues[Metric.AcousticComfort].months.join(", ") : "No issues"}</p>
                </div>

                <div className="col-start-4 col-end-5 row-start-4 row-end-5 pb-4">
                    {getMetricProposal(Metric.AcousticComfort)}
                </div>
                <div className="col-start-5 col-end-6 row-start-2 row-end-3">
                    <p>{identifiedIssues[Metric.VisualComfort] && identifiedIssues[Metric.VisualComfort].hours ? identifiedIssues[Metric.VisualComfort].hours.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.VisualComfort] && identifiedIssues[Metric.VisualComfort].days ? identifiedIssues[Metric.VisualComfort].days.join(", ") : "No issues"}</p>
                    <p>{identifiedIssues[Metric.VisualComfort] && identifiedIssues[Metric.VisualComfort].months ? identifiedIssues[Metric.VisualComfort].months.join(", ") : "No issues"}</p>
                </div>
                <div className="col-start-5 col-end-6 row-start-4 row-end-5 pb-4">
                    {getMetricProposal(Metric.VisualComfort)}
                </div>
            </div>
        </div>
    </>);
}
