import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  colors,
  Divider,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
//import { Tab, Tabs } from "@mui/base";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { RoomSensor } from "../../Common/Types";
import { Metric, Units, UnitToPretty, MetricDescription } from "../../Common/Enums";

import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import SentimentSatisfiedRoundedIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import SentimentNeutralRoundedIcon from '@mui/icons-material/SentimentNeutralRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import SentimentVeryDissatisfiedRoundedIcon from '@mui/icons-material/SentimentVeryDissatisfiedRounded';
import 'chartjs-adapter-date-fns';
import StatGauges, { CurrentStats, ValueWithBounds } from "../../components/StatGauges";
import MetricsDropdownSelect from "../../components/MetricsDropdownSelect";
import FeedbackModal from "./FeedbackModal";
import axiosInstance from "../../axiosInstance";
import axios from "axios";

ChartJS.register({ annotationPlugin });

interface LiveViewTabProps {
  roomId: number,
  selectedMetric: Metric,
  selectedSensorId: number,
  metricSensors: RoomSensor[],
  roomSensors: RoomSensor[],
  excludeAnomalies: boolean,
  metricSelected: (metric: Metric) => void
  sensorSelected: (sensorId: number) => void
  setExcludeAnomalies: Dispatch<SetStateAction<boolean>>
}

function isPointOutlier(ctx: any, outliers: boolean[]) {
  if (ctx && ctx.dataIndex) {
    return outliers[ctx.dataIndex];
  }
  return false;
}

export default function LiveViewTab(props: LiveViewTabProps) {
  const [todayChart, setTodayChart] = useState<{ labels: string[], units: Units, data: (number | null)[], outliers: boolean[], optimal_bounds: number[], yAxesTitle: string }>({ labels: Array.from({ length: 24 }, (_, x) => `${x}:00`), data: [], outliers: [], optimal_bounds: [], units: Units.None, yAxesTitle: "" });
  const [currentStats, setCurrentStats] = useState<CurrentStats>({
    air_quality: new ValueWithBounds(),
    temperature: new ValueWithBounds(),
    humidity: new ValueWithBounds(),
    acoustic_comfort: new ValueWithBounds(),
    visual_comfort: new ValueWithBounds(),
  });

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const openFeedback = () => setFeedbackOpen(true);
  const closeFeedback = () => setFeedbackOpen(false);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source(); // Create a cancel token source for request cancellation

    if (!!props.selectedMetric && !!props.roomId) {
      axiosInstance
        .get(`/api/rooms/${props.roomId}/liveview/today`, {
          params: {
            metric_id: props.selectedMetric,
            exclude_anomalies: props.excludeAnomalies,
            sensor_id: props.selectedSensorId,
          },
          cancelToken: cancelTokenSource.token, // Pass the cancel token
        })
        .then((response) => {
          const data = response.data; // Destructure data from the response
          setTodayChart((s) => ({
            ...s,
            data: data.datapoints,
            outliers: data.outliers?.map((x: number) => !!x),
            optimal_bounds: data.optimal_bounds,
            units: data.units,
            yAxesTitle: `${props.selectedMetric !== Metric.None ? Metric[props.selectedMetric] : ""} ${UnitToPretty(data.units) ? `(${UnitToPretty(data.units)})` : ""}`,
          }));
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log("Request canceled", err.message);
          } else {
            console.log(err.message);
          }
        });
    }

    // Cleanup function to cancel the axios request and reset the chart
    return () => {
      cancelTokenSource.cancel("Operation canceled by the user."); // Cancel the axios request
      setTodayChart({
        labels: Array.from({ length: 24 }, (_, x) => `${x}:00`),
        data: [],
        outliers: [],
        optimal_bounds: [],
        units: Units.None,
        yAxesTitle: "",
      });
    };
  }, [props.roomId, props.selectedMetric, props.selectedSensorId, props.excludeAnomalies]);


  // TODO reload every X minutes
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (!!props.roomId) {
      axiosInstance
        .get(`/api/rooms/${props.roomId}/liveview/latest`, {
          params: {
          },
          cancelToken: cancelTokenSource.token, // Pass the cancel token
        })
        .then((response) => {
          const data: { metric_id: Metric, value?: number, optimal_bounds: number[] }[] = response.data;
          const aq = data.find(x => x.metric_id === Metric.AirQuality);
          const hm = data.find(x => x.metric_id === Metric.Humidity);
          const ac = data.find(x => x.metric_id === Metric.AcousticComfort);
          const vc = data.find(x => x.metric_id === Metric.VisualComfort);
          const te = data.find(x => x.metric_id === Metric.Temperature);
          const stats: CurrentStats = {
            air_quality: new ValueWithBounds(aq?.value, aq?.optimal_bounds),
            humidity: new ValueWithBounds(hm?.value, hm?.optimal_bounds),
            acoustic_comfort: new ValueWithBounds(ac?.value, ac?.optimal_bounds),
            visual_comfort: new ValueWithBounds(vc?.value, vc?.optimal_bounds),
            temperature: new ValueWithBounds(te?.value, te?.optimal_bounds),
          }
          setCurrentStats(stats);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log("Request canceled", err.message);
          } else {
            console.log(err.message);
          }
        });
    }

    // Cleanup function to cancel the axios request
    return () => {
      cancelTokenSource.cancel("Operation canceled by the user."); // Cancel the axios request
    };
  }, [props.roomId]);

  return (<>
    <div className="flex">
      <div className="flex-col w-1/3">
        <div className="flex justify-start gap-8 my-3">
          <MetricsDropdownSelect selectedMetric={props.selectedMetric} metricSelected={props.metricSelected} roomSensors={props.roomSensors} />
          <p>Metric</p>
        </div>
        <div className="flex justify-start gap-8 my-3">
          <Select
            value={props.selectedSensorId}
            onChange={(event) => props.sensorSelected(event.target.value as number)}
            sx={{
              width: `185px`,
              ".MuiOutlinedInput-input": {
                padding: 0
              }
            }}
          >
            <MenuItem value={-1} key={-1}>All</MenuItem>
            {
              props.metricSensors.map(x => (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>))
            }
          </Select>
          <p>Sensor</p>
        </div>

        <div className="flex justify-start gap-8 my-3">
          <Switch
            checked={props.excludeAnomalies}
            onChange={(_, checked) => props.setExcludeAnomalies(checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p>Exclude anomalies</p>
        </div>
        <div className="my-3 text-start">
          <p>Provide Feedback</p>

          <button onClick={openFeedback}>
            <SentimentVerySatisfiedRoundedIcon sx={{ color: colors.green[800] }} />
            <SentimentSatisfiedRoundedIcon sx={{ color: colors.green[300] }} />
            <SentimentNeutralRoundedIcon sx={{ color: colors.yellow[700] }} />
            <SentimentDissatisfiedRoundedIcon sx={{ color: colors.orange[700] }} />
            <SentimentVeryDissatisfiedRoundedIcon sx={{ color: colors.red[500] }} />
          </button>
          <FeedbackModal open={feedbackOpen} onClose={closeFeedback} roomId={props.roomId} />

        </div>
        <p className="my-3 text-start">&lt;Metric description&gt;
          <br />
          {MetricDescription(props.selectedMetric)}
        </p>
      </div>
      <div className="w-2/3">
        <Chart
          type="line"
          data={{
            labels: todayChart.labels,
            datasets: [{
              data: todayChart.data,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1,
              pointBorderColor: "red",
              pointBackgroundColor: 'rgb(75, 192, 192)',

            }],
          }}
          options={{
            elements: {
              point: {
                pointStyle: (ctx) => isPointOutlier(ctx, todayChart.outliers) ? 'crossRot' : 'circle',
                radius: (ctx) => isPointOutlier(ctx, todayChart.outliers) ? 5 : 0,
                borderWidth: (ctx) => isPointOutlier(ctx, todayChart.outliers) ? 3 : 0,
                backgroundColor: "red"
              }
            },
            scales: {
              y: {
                suggestedMin: 0,
                title: {
                  display: true,
                  text: todayChart.yAxesTitle,
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              annotation: todayChart.optimal_bounds && todayChart.optimal_bounds.length === 2 ? {
                annotations: {
                  line1: {
                    drawTime: "afterDatasetsDraw",
                    borderColor: "rgba(0, 255, 255, 0.25)",
                    backgroundColor: "rgba(0, 255, 255, 0.25)",
                    type: "box",
                    yMin: todayChart.optimal_bounds[0],
                    yMax: todayChart.optimal_bounds[1],
                    borderWidth: 1
                  },
                },
              } : {},
            },
          }}
        />
      </div>
    </div>
    <div className="mt-4">
      <Divider
        variant="middle"
        style={{
          borderBottomColor: colors.grey[500],
        }}
      />
    </div>
    <StatGauges stats={currentStats} isRoomLiveViewPresentation={true}></StatGauges>
  </>);
}