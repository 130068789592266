import { Units, UnitToPretty } from "../../Common/Enums";
import { ValueWithBounds } from "../StatGauges";
import ScaleLine, { TickMarkDescriptor } from "./ScaleLine";

export default function HumidityScaleLine({ valueWithBounds }: { valueWithBounds: ValueWithBounds }) {

  const tickMarkers: TickMarkDescriptor[] = valueWithBounds && valueWithBounds.hasBounds()
    ? [{
      colorTillNextTickMark: "green",
      tickValue: valueWithBounds.optimal_bounds![0],
    }, {
      colorTillNextTickMark: "red",
      tickValue: valueWithBounds.optimal_bounds![1],
    }]
    : [];

  return (
    <ScaleLine
      startValue={0}
      endValue={100}
      colorTillFirstTickMark="red"
      currentValue={valueWithBounds.value}
      totalWidth={150}
      tickMarkers={tickMarkers}
      units={UnitToPretty(Units.Percent)}
    ></ScaleLine>
  );
}
