import { Outlet, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { colors, Divider, Card, CardContent } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { Metric, Units, UnitToPretty } from "../../Common/Enums";

import axios from "axios";
import axiosInstance from "../../axiosInstance";
import StatGauges, { CurrentStats, GetDefaultCurrentStatsValues, ValueWithBounds } from "../../components/StatGauges";

function Gauges() {
  return (
    <div className="flex grow">
      <div className="mx-3">
        <Gauge
          width={100}
          height={100}
          value={600}
          valueMax={600}
          text={({ value }) => `${value}\n${UnitToPretty(Units.PPM)}`}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontWeight: "bold",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: colors.yellow[300],
            },
            //   [`& .${gaugeClasses.referenceArc}`]: {
            //     fill: theme.palette.text.disabled,
            //   },
          })}
        />
        <div className="text-xs">Air Quality</div>
      </div>
      <div className="inline-flex bg-yellow-100 flex-col">
        <div className="inline-flex">
          <div className="mx-3">
            <Gauge
              width={100}
              height={100}
              value={21}
              valueMax={21}
              text={({ value }) => `${value}\n${UnitToPretty(Units.DegreesCelsius)}`}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontWeight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: colors.green[300],
                },
              }}
            />
            <div className="text-xs">Temperature</div>
          </div>
          <div className="mx-3">
            <Gauge
              width={100}
              height={100}
              value={72}
              valueMax={72}
              text={({ value }) => `${value}\n${UnitToPretty(Units.Percent)}`}
              sx={{
                [`& .${gaugeClasses.valueText}`]: {
                  fontWeight: "bold",
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: colors.red[200],
                },
              }}
            />
            <div className="text-xs">Humidity</div>
          </div>
        </div>
        <Divider
          variant="middle"
          style={{
            margin: 6,
            borderBottomWidth: 1,
            borderBottomColor: colors.grey[500],
          }}
        />
        <div className="block text-sm mb-1">Thermal Comfort</div>
      </div>
      <div className="mx-3">
        <Gauge
          width={100}
          height={100}
          value={20}
          valueMax={20}
          text={({ value }) => `${value}\n${UnitToPretty(Units.Decibel)}`}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontWeight: "bold",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: colors.green[300],
            },
          }}
        />
        <div className="text-xs">Acoustic Comfort</div>
      </div>
      <div className="mx-3">
        <Gauge
          width={100}
          height={100}
          value={100}
          valueMax={100}
          text={({ value }) => `${value}\n${UnitToPretty(Units.Lux)}`}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontWeight: "bold",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: colors.green[300],
            },
          }}
        />
        <div className="text-xs">Visual Comfort</div>
      </div>
    </div>
  );
}

type RoomProperties = {
  room_id: number;
  room_name: string;
  function: string;
  floor: number;
  sector: string;
  status: number;
};

interface BasicCardProps {
  room: RoomProperties;
}

function BasicCard({ room }: BasicCardProps) {
  return (
    <Link to={`/room/${room.room_id}`}>
      <Card
        sx={{
          width: 205,
          height: 100,
          border: 1,
          borderColor:
            room.floor === 0
              ? colors.red[500]
              : room.floor === 1
                ? colors.green[500]
                : colors.orange[500],
          userSelect: "none",
        }}
        className="shrink-0"
        raised
      >
        <CardContent>
          <div>{room.room_name}</div>
          <div>{room.function}</div>
          <div>Floor {room.floor}</div>
        </CardContent>
        {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </Card>
    </Link>
  );
}

function Rooms({ rooms }: { rooms: RoomProperties[] }) {
  const groupedRooms = groupByFloorAsList(rooms);

  return (
    <div>
      {groupedRooms.map(({ floor, rooms }) => {
        const sortedRooms = sortRooms(rooms);

        return (
          <div key={floor} style={{ marginLeft: "20px" }}>
            <h2 style={{ textAlign: "left" }}>Floor {floor}</h2>
            <div className="flex flex-wrap justify-start gap-4 m-2">
              {sortedRooms.map((room) => (
                <BasicCard key={room.room_id} room={room} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default function BuildingOverview({ pilotId }: { pilotId: number }) {
  const [rooms, setRooms] = useState<RoomProperties[]>([]);
  const [currentStats, setCurrentStats] = useState<CurrentStats>(GetDefaultCurrentStatsValues());


  useEffect(() => {
    setRooms([]);
    const source = axios.CancelToken.source();
    axiosInstance.get(
      `/api/rooms`,
      {
        params: {},
        cancelToken: source.token  // Use the cancel token in axios
      }
    )
      .then((response) => {
        const data = response.data;
        setRooms(data);  // Handle the response data
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          console.log(err.message);
        }
      });


    // Cleanup to cancel the request when the component is unmounted or dependencies change
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [pilotId]);

  // TODO reload every X minutes
  useEffect(() => {
    setCurrentStats(GetDefaultCurrentStatsValues());
    const cancelTokenSource = axios.CancelToken.source();
    axiosInstance
      .get(`/api/all_stats`, {
        params: {
        },
        cancelToken: cancelTokenSource.token, // Pass the cancel token
      })
      .then((response) => {
        const data: { metric_id: Metric, value?: number, optimal_bounds: number[] }[] = response.data;
        const aq = data.find(x => x.metric_id === Metric.AirQuality);
        const hm = data.find(x => x.metric_id === Metric.Humidity);
        const ac = data.find(x => x.metric_id === Metric.AcousticComfort);
        const vc = data.find(x => x.metric_id === Metric.VisualComfort);
        const te = data.find(x => x.metric_id === Metric.Temperature);
        const stats: CurrentStats = {
          air_quality: new ValueWithBounds(aq?.value, aq?.optimal_bounds),
          humidity: new ValueWithBounds(hm?.value, hm?.optimal_bounds),
          acoustic_comfort: new ValueWithBounds(ac?.value, ac?.optimal_bounds),
          visual_comfort: new ValueWithBounds(vc?.value, vc?.optimal_bounds),
          temperature: new ValueWithBounds(te?.value, te?.optimal_bounds),
        }
        setCurrentStats(stats);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          console.log(err.message);
        }
      });


    // Cleanup function to cancel the axios request
    return () => {
      cancelTokenSource.cancel("Operation canceled by the user."); // Cancel the axios request
    };
  }, [pilotId]);

  return (
    <div className="min-w-[1000px] overflow-x-auto">
      <div className="flex my-2">
        <div className="min-w-64 mx-3">
          <div className="font-bold">IEQ Web Service​</div>
          <div>ELLET building in Tripodon street,</div>
          <div>Athens, Greece</div>
        </div>
        <div className="flex-5">
          <StatGauges stats={currentStats} isRoomLiveViewPresentation={false}></StatGauges>
        </div>
      </div>
      <Divider
        variant="middle"
        style={{
          borderBottomColor: colors.grey[500],
        }}
      />
      <Rooms rooms={rooms} />
    </div>
  );
}


const groupByFloorAsList = (rooms: RoomProperties[]): { floor: number; rooms: RoomProperties[] }[] => {
  const grouped = new Map<number, RoomProperties[]>();

  rooms.forEach((room) => {
    if (!grouped.has(room.floor)) {
      grouped.set(room.floor, []);
    }
    grouped.get(room.floor)!.push(room);
  });

  const groupedList = Array.from(grouped.entries())
    .map(([floor, rooms]) => ({ floor, rooms }))
    .sort((a, b) => a.floor - b.floor); // Sort by floor number

  return groupedList;
};

const sortRooms = (rooms: RoomProperties[]): RoomProperties[] => {
  return rooms.sort((a, b) => {
    if (a.sector !== b.sector) {
      return a.sector.localeCompare(b.sector); // Sort by sector alphabetically
    }
    return a.room_id - b.room_id; // Sort by room_id numerically
  });
};