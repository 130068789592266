import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

let selectedPilotGlobal: number | null = null; // Module-level variable for global access

interface PilotContextType {
    pilots: number[];
    setPilots: (pilots: number[]) => void;
    selectedPilot: number | null;
    setSelectedPilot: (pilot: number | null) => void;
}

const PilotContext = createContext<PilotContextType | undefined>(undefined);

export const PilotProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [pilots, setPilotsState] = useState<number[]>([]);
    const [selectedPilot, setSelectedPilotState] = useState<number | null>(null);

    const setSelectedPilot = useCallback((pilot: number | null) => {
        selectedPilotGlobal = pilot;
        setSelectedPilotState(pilot);
    }, []);

    const setPilots = useCallback((pilots: number[]) => {
        setSelectedPilot(pilots[0]);
        setPilotsState(pilots);
    }, []);

    return (
        <PilotContext.Provider value={{ pilots, setPilots, selectedPilot, setSelectedPilot }}>
            {children}
        </PilotContext.Provider>
    );
};

export const usePilotContext = (): PilotContextType => {
    const context = useContext(PilotContext);
    if (!context) {
        throw new Error("usePilotContext must be used within a PilotProvider");
    }
    return context;
};


export const getSelectedPilot = (): number | null => selectedPilotGlobal;